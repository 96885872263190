import { useQuery } from '@apollo/client'
import { Container, ProfilePayment, ProfilePayout, ProfileShipping } from '@components'
import { Button } from '@elements'
import {
  equipment as graphqlEquipment,
  golfClubType as graphqlGolfClubType, paymentCard as graphqlPaymentCard
} from '@graphql'
import { useLocationWithState, useWindowSize } from '@hooks'
import InfoIcon from '@images/info-icon.svg'
import { CheckoutPage } from '@pages/components'
import { AddressCard, GolfClubType, GolfEquipmentType, PaymentCard, ProductPageItemType, ResponseType, UserPayoutCard } from '@types'
import { navigate } from 'gatsby'
import { isGolfClubByType } from '@utils'
import React, { useEffect, useState } from 'react'
import '../checkout.scss'

interface CheckoutAddressProps {
  id: string
}

const CheckoutAddress: React.FC<CheckoutAddressProps> = ({ id }) => {
  const { toRender: isMobileRender } = useWindowSize(['mobile', 'landscape'])
  const { pathname, state } = useLocationWithState<{ type: ProductPageItemType, backUrl: string }>()

  const itemType = state.type
  const isGolfClubPage = itemType === "GolfClubModel"
  const backUrl = state?.backUrl

  const golfItemTypeQuery = isGolfClubPage ? graphqlGolfClubType.FetchGolfClubType : graphqlEquipment.FetchGolfEquipmentType
  const { data: golfTypeData } = useQuery<ResponseType<GolfEquipmentType | GolfClubType>>(golfItemTypeQuery, {
    variables: { id }
  })

  const { data: userPayoutCard } = useQuery<ResponseType<UserPayoutCard>>(graphqlPaymentCard.FetchStripePayoutCard, {
    fetchPolicy: 'network-only'
  })

  const [selectedUserCard, setSelectedUserCard] = useState<PaymentCard | null>(null)
  const [selectedAddressCard, setSelectedAddressCard] = useState<AddressCard | null>(null)
  const [isPaymentInfoFormOpen, setIsPaymentInfoFormOpen] = useState(false)
  const [isPaymentInfoLoading, setIsPaymentInfoLoading] = useState(false)
  const [isShippingInfoFormOpened, toggleShippingInfoForm] = useState(false)


  const userPayoutMethod = userPayoutCard && userPayoutCard.res && userPayoutCard.res.id

  const stripeReturnUrl = !!selectedAddressCard && !!selectedUserCard ? backUrl : pathname


  const isBackUrlSellPage = backUrl?.includes('sell')

  const golfType = (golfTypeData && golfTypeData.res) || ({} as GolfClubType | GolfEquipmentType)


  // bread crumbs
  const categoryTitle = golfType?.category?.title
  const brandTitle = golfType?.brand?.title
  const modelTitle = isGolfClubByType(golfType) ? golfType.golfClubModel?.title : golfType.golfEquipmentModel?.title
  const breadCrumbs = [categoryTitle, brandTitle, modelTitle].join(' / ')
  //

  // for away url location state
  const golfItemType = isGolfClubPage ? 'golfClub' : 'golfEquipment'

  const handleUserCardSelect = (userCard?: PaymentCard | null) => {
    setSelectedUserCard(userCard as PaymentCard)
  }
  const handleAddressCardSelect = (addressCard?: AddressCard | null) => {
    setSelectedAddressCard(addressCard as AddressCard)
  }
  const handleDeleteSelectedUserCard = () => {
    setSelectedUserCard(null)
  }
  const handleDeleteAddress = async () => {
    setSelectedAddressCard(null)
  }


  const handleConfirmUserCard = async () => {

    if (!backUrl) {
      await navigate(backUrl, { state: { type: itemType } })
    } else {
      await navigate(backUrl, {
        state: { selectedUserCard, type: itemType }
      })
    }
  }

  const handleConfirmAddress = async () => {
    if (!backUrl) {
      await navigate(backUrl, { state: { type: itemType } })
    } else {
      if (!selectedAddressCard) {
        await navigate(backUrl, { state: { selectedAddressCard: null, type: itemType } })
      }
      await navigate(backUrl, { state: { selectedAddressCard, type: itemType } })
    }
  }

  const handleAllConfirm = async () => {

    if (!selectedAddressCard || !selectedUserCard) {
      await navigate(backUrl, {
        state: { selectedAddressCard: null, selectedUserCard: null, type: itemType }
      })
    }

    await navigate(backUrl, {
      state: { selectedUserCard, selectedAddressCard, type: itemType }
    })

  }

  /**
   * Press Cancel button
   * navigate to backUrl + state - golfItemType
   */
  const handleCancel = async () => {
    await navigate(backUrl, { state: { type: itemType } })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [isPaymentInfoFormOpen, isShippingInfoFormOpened])

  return (
    <section className="checkout">
      <Container withCard={!isMobileRender}>
        <div className="checkout-main">
          <CheckoutPage.BasicInfo breadCrumbs={breadCrumbs} golfType={golfType} />
          <article className="checkout-address">
            <div className="checkout-address-form">
              <div className={'checkout-payment'}>
                <div className={'checkout-payment-hint'}>
                  <div className={'checkout-payment-hint-icon'}>
                    <InfoIcon />
                  </div>
                  <div className={'checkout-payment-hint-label'}>
                    {isBackUrlSellPage
                      ? 'In order to complete your sale, please add your payment, shipping, and payout info.'
                      : 'In order to make an offer or complete your purchase, please add your payment and shipping info.'}
                  </div>
                </div>

                <div className={'checkout-payment-body'}>
                  {!isShippingInfoFormOpened && (
                    <>
                      <div className={'checkout-payment-header'}>
                        <strong className={'subtitle2 uppercase'}>Payment Info</strong>
                      </div>
                      <div className={'checkout-payment-body-payment'}>
                        <ProfilePayment
                          noDataMessage={
                            isBackUrlSellPage
                              ? 'Add a payment method to complete your sale'
                              : 'Add a payment method to complete you purchase'
                          }
                          onDelete={handleDeleteSelectedUserCard}
                          onSelect={handleUserCardSelect}
                          handleSubmitSuccess={handleConfirmUserCard}
                          handleLoading={loading => {
                            setIsPaymentInfoLoading(loading)
                          }}
                          handleFormOpen={setIsPaymentInfoFormOpen}
                          createCardAsDefault
                        />
                      </div>
                    </>
                  )}
                  {isBackUrlSellPage && !isShippingInfoFormOpened && !isPaymentInfoFormOpen && (
                    <>
                      <div className={'checkout-payment-header'}>
                        <strong className={'subtitle2 uppercase'}>Payout Info</strong>
                      </div>
                      <div className={'checkout-payment-body-payout'}>
                        <ProfilePayout stripeReturnUrl={stripeReturnUrl} payoutMethod={userPayoutMethod} />
                      </div>
                    </>
                  )}
                  {!isPaymentInfoFormOpen && (
                    <>
                      <div className={'checkout-payment-header'}>
                        <strong className={'subtitle2 uppercase'}>Shipping Info</strong>
                      </div>

                      <div className={'checkout-payment-body-shipping'}>
                        <ProfileShipping
                          noDataMessage={
                            isBackUrlSellPage
                              ? 'Add a shipping address to complete your sale'
                              : 'Add a shipping address to complete your purchase'
                          }
                          handleConfirmAddress={handleConfirmAddress}
                          isShippingInfoFormOpened={isShippingInfoFormOpened}
                          toggleShippingInfoForm={toggleShippingInfoForm}
                          direction="vertical"
                          onAddressCardDelete={handleDeleteAddress}
                          onAddressCardSelect={handleAddressCardSelect}
                        />
                      </div>
                    </>
                  )}
                </div>
                {!isPaymentInfoFormOpen && !isShippingInfoFormOpened && (
                  <div className={'checkout-payment-button-group'}>
                    <Button type={'white'} size={'md'} onClick={handleCancel} centered>
                      Cancel
                    </Button>
                    <Button
                      type={'darkGreen'}
                      size={'md'}
                      onClick={handleAllConfirm}
                      disabled={!selectedUserCard || !selectedAddressCard}
                      centered
                      loading={isPaymentInfoLoading}
                    >
                      REVIEW
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </article>
        </div>
      </Container>
    </section>
  )
}

export default CheckoutAddress
